import React, { useState, useEffect } from "react";
import axios from "axios";
import "./liveText.scss";

const LiveText = () => {
  const [nowPlaying, setNowPlaying] = useState();

  const getNowPlaying = async () => {
    let cacheBust = new Date().getTime();
    const response = await axios(
      `https://public.radio.co/stations/sc551376ec/status?=${cacheBust}`
    );

    setNowPlaying(response.data);
  };

  useEffect(() => {
    getNowPlaying();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => getNowPlaying(), 20000);
    return () => clearTimeout(timeout);
  }, [nowPlaying]);

  if (!nowPlaying) return <span className="play-bar__text"></span>;

  if (nowPlaying.source.type === "live")
    return <span className="play-bar__text">Live!</span>;

  if (nowPlaying.current_track)
    return (
      <span className="play-bar__text">{nowPlaying.current_track.title}</span>
    );

  return <span className="play-bar__text"></span>;
};

export default LiveText;
