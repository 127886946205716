import React, { useState, useRef } from "react";
import PlayButton from "./PlayButton/PlayButton";
import LiveText from "./LiveText/LiveText";
import "./playBar.scss";

const PlayBar = () => {
  const [playing, setPlaying] = useState(false);
  const audioElement = useRef();

  const audioUrl = "https://s5.radio.co/sc551376ec/listen";

  const playPauseHandler = () => {
    if (audioElement.current.paused) {
      let cacheBust = new Date().getTime();
      audioElement.current.src = audioUrl + "?" + cacheBust;
      audioElement.current.play();
      setPlaying(true);
    } else {
      audioElement.current.pause();
      audioElement.current.src = "";
      setPlaying(false);
    }
  };

  return (
    <div className="play-bar">
      <audio
        ref={audioElement}
        src={audioUrl}
        type="audio"
        preload="metadata"
      />
      <PlayButton clicked={() => playPauseHandler()} audioStatus={playing} />
      <LiveText />
    </div>
  );
};

export default PlayBar;
