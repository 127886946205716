import React, { useState, useEffect } from "react";
import axios from "axios";
import "./schedule.scss";

const Schedule = () => {
  const [scheduleItems, setScheduleItems] = useState([]);

  const getSchedule = async () => {
    let cacheBust = new Date().getTime();
    const response = await axios(
      `https://orchid.fm/wp-json/wp/v2/jolt-cal?=${cacheBust}`
    );

    setScheduleItems(response.data);
  };

  useEffect(() => {
    getSchedule();
  }, []);

  const renderEvents = (items) => {
    return items.map((item, i) => {
      let title = item.title;

      return (
        <div key={i} className="schedule__entry">
          <div className="schedule__time">{item.start_time_text}</div>
          <div className="schedule__item">{title}</div>
        </div>
      );
    });
  };

  if (!scheduleItems) return <div>Nothing scheduled.</div>;

  return scheduleItems.map((day, i) => {
    return (
      <div key={i}>
        <div className="schedule__date">{day.date}</div>
        {renderEvents(day.events)}
      </div>
    );
  });
};

export default Schedule;
