import React from "react";
import PlayBar from "./PlayBar/PlayBar";
import "./header.scss";

const header = (props) => (
  <header className="header">
    <h2 className="header__title">Orchid.FM</h2>
    <PlayBar />
  </header>
);

export default header;
