import React from "react";
import "./playButton.scss";
import Icon from "../../../UI/Icon/Icon";

const playButton = (props) => {
  return (
    <div onClick={props.clicked} className="" aria-label="Play / Pause">
      {props.audioStatus ? (
        <Icon name="stop" parentClass="play-bar" />
      ) : (
        <Icon name="play" parentClass="play-bar" />
      )}
    </div>
  );
};

export default playButton;
