import Symbols from "./components/Symbols/Symbols";
import Header from "./components/Header/Header";
import Schedule from "./components/Schedule/Schedule";
import "./scss/App.scss";
import logo from "./logo.png";
import flyer from "./flyer.jpg";

function App() {
  return (
    <div className="grid">
      <Symbols />
      <div className="span-col-3">
        <Header />
      </div>
      <div className="span-col-2 span-row-3">
        <img src={flyer} className="logo" alt="Orchid.fm" />
      </div>
      <div className="span-row-3">
        <div className="schedule">
          <Schedule />
        </div>
      </div>

      <div className="span-col-3">
        <marquee>NEW WEBSITE IN PROGRESS !!!</marquee>
      </div>
    </div>
  );
}

export default App;
